<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="card-body">
          <div class="row" style="background-color: #f3fdff">
            <div class="col-3 container">
              <img
                src="resources/images/medisync-big.svg"
                alt="Medisync"
                height="50px"
              />
            </div>
            <div class="col-6 header-text container">
              Daftar Pemeriksaan Lab Patologi Klinis
            </div>
            <div class="col-3 container">
              {{ name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row px-4 mb-4">
        <div class="col-2 p-0 pr-3">
          <a-date-picker
            v-model="selectedDate"
            format="YYYY-MM-DD"
            style="width: 100%"
            @change="applyFilters"
          />
        </div>
        <div class="col-4 p-0 pr-3">
          <a-select
            ref="select"
            style="width: 100%"
            v-model="selectedStatus"
            @change="applyFilters"
            class="select-custom"
          >
            <a-select-option
              v-for="(status, idx) in statusList"
              :value="status.name"
              :key="idx"
            >
              {{ status.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-6 p-0">
          <SearchOutlined
            style="position: absolute; z-index: 999999; top: 8px; left: 15px"
          />
          <a-input
            placeholder="Ketik no permintaan, no rekam medis, atau nama pasien untuk mencari..."
            style="height: 31.5px; padding-left: 45px"
            v-model="searchQuery"
            @input="applyFilters"
          />
        </div>
      </div>

      <a-table
        :dataSource="filteredTableData"
        :columns="columns"
        rowKey="id"
        @rowClick="handleRowDataClick"
      >
        <template slot="actions" slot-scope="record">
          <div class="d-flex justify-content-end">
            <button
              class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
              style="width: 100px; border-radius: 26px"
              @click.prevent="goToDetail(record)"
            >
              <img
                src="/resources/images/edit.svg"
                alt="edit"
                class="icon-default"
              />
              <img
                src="/resources/images/edit-hover.svg"
                alt="edit-hover"
                class="icon-hover"
              />
              <span class="ml-2">Ubah</span>
            </button>
            <button
              @click.prevent="handleToggleDelete(record.id)"
              class="d-flex align-items-center justify-content-center btn btn-outline-danger delete-button"
              style="width: 100px; border-radius: 26px"
            >
              <img
                src="/resources/images/delete.svg"
                alt="delete"
                class="icon-default"
              />
              <img
                src="/resources/images/delete-hover.svg"
                alt="delete-hover"
                class="icon-hover"
              />
              <span class="ml-2">Hapus</span>
            </button>
          </div>
        </template>
        <template slot="status" slot-scope="text">
          <div
            :style="{
              backgroundColor: getStatusBackgroundColor(text),
              color: getStatusColor(text),
              borderRadius: '999px',
              textAlign: 'center',
              // width: '132px',
            }"
            class="py-1"
          >
            {{ text }}
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      v-model="modalDeleteVisible"
      title="Hapus Pemeriksaan?"
      @ok="handleDelete"
      centered
    >
      <WarningOutlined />
      <span class="ml-2">Yakin Ingin Menghapus Data?</span>
    </a-modal>
  </div>
</template>

<script>
import {
  deleteExaminations,
  getExaminationRequest,
} from "@/services/axios/api/pemeriksaan"
import { WarningOutlined, SearchOutlined } from "@ant-design/icons-vue"

export default {
  components: { WarningOutlined, SearchOutlined },
  data() {
    return {
      name: "",
      selectedDate: null,

      filterText: "", // State for the filter input,
      tableData: [
        {
          No: 1,
          noPermintaan: "PK1234567890",
          noRekamMedis: "1234567890",
          namaPasien: "Muhammad Ramadan",
          tanggal: "10 Juli 2024",
          waktu: "13:30:04",
          perujuk: "dr. Nur Fajar",
          kategori: "BPJS Kesehatan",
          status: "Menunggu Spesimen",
        },
        {
          No: 2,
          noPermintaan: "PK1234567890",
          noRekamMedis: "1234567890",
          namaPasien: "Ariel Rupawan",
          tanggal: "10 Juli 2024",
          waktu: "13:48:35",
          perujuk: "dr. Nur Fajar",
          kategori: "Mandiri",
          status: "Menunggu Spesimen",
        },
        {
          No: 3,
          noPermintaan: "PK1234567890",
          noRekamMedis: "1234567890",
          namaPasien: "Budi Prasetyo",
          tanggal: "10 Juli 2024",
          waktu: "14:00:30",
          perujuk: "dr. Nur Fajar",
          kategori: "Mandiri",
          status: "Menunggu Spesimen",
        },
        {
          No: 4,
          noPermintaan: "PK1234567890",
          noRekamMedis: "1234567890",
          namaPasien: "Claudia S",
          tanggal: "10 Juli 2024",
          waktu: "14:00:30",
          perujuk: "dr. Dimas Prasetya",
          kategori: "Asuransi lainnya",
          status: "Menunggu Pemeriksaan",
        },
        // Data lainnya...
      ],
      filteredTableData: [
        {
          No: 1,
          noPermintaan: "PK1234567890",
          noRekamMedis: "1234567890",
          namaPasien: "Muhammad Ramadan",
          tanggal: "10 Juli 2024",
          waktu: "13:30:04",
          perujuk: "dr. Nur Fajar",
          kategori: "BPJS Kesehatan",
          status: "Menunggu Spesimen",
        },
        {
          No: 2,
          noPermintaan: "PK1234567890",
          noRekamMedis: "1234567890",
          namaPasien: "Ariel Rupawan",
          tanggal: "10 Juli 2024",
          waktu: "13:48:35",
          perujuk: "dr. Nur Fajar",
          kategori: "Mandiri",
          status: "Menunggu Spesimen",
        },
        {
          No: 3,
          noPermintaan: "PK1234567890",
          noRekamMedis: "1234567890",
          namaPasien: "Budi Prasetyo",
          tanggal: "10 Juli 2024",
          waktu: "14:00:30",
          perujuk: "dr. Nur Fajar",
          kategori: "Mandiri",
          status: "Menunggu Spesimen",
        },
        {
          No: 4,
          noPermintaan: "PK1234567890",
          noRekamMedis: "1234567890",
          namaPasien: "Claudia S",
          tanggal: "10 Juli 2024",
          waktu: "14:00:30",
          perujuk: "dr. Dimas Prasetya",
          kategori: "Asuransi lainnya",
          status: "Menunggu Pemeriksaan",
        },
        // Data lainnya...
      ],
      realTableData: [],
      statusList: [
        {
          name: "Semua",
          value: "Semua",
        },
        {
          name: "Menunggu Spesimen",
          value: "Menunggu Spesimen",
        },
        {
          name: "Menunggu Pemeriksaan",
          value: "Menunggu Pemeriksaan",
        },
        {
          name: "Selesai Diperiksa",
          value: "Selesai Diperiksa",
        },
      ],
      dataSource: [],
      filteredDataSource: [],
      columns: [
        {
          title: "No",
          dataIndex: "key",
          key: "key",
        },
        {
          title: "Nomor Permintaan",
          dataIndex: "noPermintaanLab",
          key: "noPermintaanLab",
        },
        {
          title: "Nomor Rekam Medis",
          dataIndex: "Visit.Patient.medical_record_no",
          key: "noRekamMedis",
        },
        {
          title: "Nama Pasien",
          dataIndex: "Visit.Patient.name",
          key: "namaPasien",
        },
        {
          title: "Tanggal",
          dataIndex: "tanggal",
          key: "tanggal",
        },
        {
          title: "Waktu",
          dataIndex: "waktu",
          key: "waktu",
        },
        {
          title: "Perujuk",
          dataIndex: "doctorName",
          key: "doctorName",
        },
        {
          title: "Kategori",
          dataIndex: "Visit.Patient.payment_method",
          key: "kategori",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
      ],
      categories: [],
      selectedStatus: "Semua",
      modalDeleteVisible: false,
      selectedDeleteId: "",
      searchQuery: "",
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },
  methods: {
    applyFilters() {
      const query = this.searchQuery.toLowerCase()
      this.filteredTableData = this.realTableData.filter((e) => {
        const matchesStatus =
          this.selectedStatus === "Semua" || e.status === this.selectedStatus

        const matchesDate =
          !this.selectedDate ||
          new Date(e.tanggal).toISOString().split("T")[0] ===
            this.selectedDate.toISOString().split("T")[0]

        const matchesSearch =
          !query ||
          e.id.toString().includes(query) ||
          (e.Visit?.Patient?.medical_record_no &&
            e.Visit.Patient.medical_record_no.includes(query)) ||
          (e.Visit?.Patient?.name &&
            e.Visit.Patient.name.toLowerCase().includes(query))

        return matchesStatus && matchesDate && matchesSearch
      })
    },
    getName() {
      const name = localStorage.getItem("name")
      this.name = name
    },
    async fetchData() {
      try {
        const { data } = await getExaminationRequest()
        console.log(data, "ini?")

        // this.tableData = data.data.map((e, idx) => {
        //   const formattedDate = e.createdAt.split('T')[0] // Extract YYYY-MM-DD
        //   const formattedTime = e.createdAt.split('T')[1].split('.')[0] // Extract HH:mm:ss
        //   return {
        //     ...e,
        //     tanggal: formattedDate,
        //     waktu: formattedTime,
        //     doctorName: e.Visit.Doctor.User.name,
        //     key: idx + 1,
        //   }
        // })
        console.log("disiniaman?")

        this.filteredTableData = data.data.map((e, idx) => {
          const formattedDate = e.createdAt.split("T")[0] // Extract YYYY-MM-DD
          const formattedTime = e.createdAt.split("T")[1].split(".")[0] // Extract HH:mm:ss
          return {
            ...e,
            tanggal: formattedDate,
            waktu: formattedTime,
            doctorName: "dr. " + e.Visit.Doctor.User.name,
            key: idx + 1,
          }
        })

        this.realTableData = data.data.map((e, idx) => {
          const formattedDate = e.createdAt.split("T")[0] // Extract YYYY-MM-DD
          const formattedTime = e.createdAt.split("T")[1].split(".")[0] // Extract HH:mm:ss
          return {
            ...e,
            tanggal: formattedDate,
            waktu: formattedTime,
            doctorName: "dr. " + e.Visit.Doctor.User.name,
            key: idx + 1,
          }
        })
        console.log(this.filteredTableData, "inikocak")
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    },
    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase()
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeData(selectedStatus) {
      if (selectedStatus !== "Semua") {
        this.filteredTableData = this.realTableData.filter(
          (e) => e.status === selectedStatus,
        )
      } else {
        this.filteredTableData = this.realTableData
      }
    },
    handleDateChange(date) {
      if (!date) return
      const newDate = date.toISOString().split("T")[0]
      this.dateQuery = newDate
      // console.log(this.dateQuery, "tanggal");
      // this.getVisit()
    },
    handleAddExamination() {
      this.$router.push("/laborant/add")
    },
    goToDetail(record) {
      console.log(record, "<<<")
      this.$router.push(`/laborant/detail/${record.id}`)
    },
    async handleToggleDelete(id) {
      console.log(id)
      this.selectedDeleteId = id
      this.modalDeleteVisible = !this.modalDeleteVisible
    },
    async handleDelete() {
      try {
        await deleteExaminations("master", this.selectedDeleteId)
        this.fetchData() // Refresh data setelah penghapusan
        this.modalDeleteVisible = false
      } catch (error) {
        console.error("Error deleting data:", error)
      }
    },
    getStatusColor(status) {
      const statusColorMap = {
        "Menunggu Perawat": "#875BF7",
        "Menunggu Spesimen": "#EF6820",
        "Diperiksa Dokter": "#2E90FA",
        "Menunggu Pemeriksaan": "#2E90FA",
        "Selesai Diperiksa": "#16B364",
      }
      return statusColorMap[status]
    },
    getStatusBackgroundColor(status) {
      const statusColorMap = {
        "Menunggu Perawat": "#F3F1FF",
        "Menunggu Spesimen": "#FFF2E4",
        "Diperiksa Dokter": "#E1F1FF",
        "Menunggu Pemeriksaan": "#E1F1FF",
        "Selesai Diperiksa": "#E1FBEA",
      }
      return statusColorMap[status]
    },
    handleRowDataClick(record) {
      if (record.status === "Menunggu Spesimen") {
        this.$router.push("/laborant/klinik/spesimen/" + record.id)
      } else if (record.status === "Menunggu Pemeriksaan") {
        this.$router.push("/laborant/klinik/pemeriksaan/" + record.id)
      } else {
        this.$router.push("/laborant/klinik/detail/" + record.id)
      }
      console.log(record.status)
      console.log(record.id)
    },
  },
}
</script>

<style scoped>
.select-custom .ant-select-selector {
  padding-left: 12px !important;
  /* Sesuaikan padding sesuai kebutuhan */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.delete-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.delete-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.delete-button:hover .icon-default {
  display: none;
}

.delete-button:hover .icon-hover {
  display: inline;
}

/* Default icon terlihat */
.edit-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.edit-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.edit-button:hover .icon-default {
  display: none;
}

.edit-button:hover .icon-hover {
  display: inline;
}
</style>
